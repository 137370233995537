import '@fontsource/ubuntu/400.css'
import '@fontsource/ubuntu/500.css'
import '@fontsource/ubuntu/700.css'
import 'Shared/sass/style.scss'
import './src/sass/style.scss'

import { removeAllParamsWhenUTM } from 'Shared/utils/removeUTM'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = () => {
  if (document.querySelector(`.pux-notfound`)) {
    removeAllParamsWhenUTM(window.location.href)
  }
}
